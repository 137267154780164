<template>
  <span style="padding: 24px;">Redirecting...</span>
</template>

<script setup>
import { getInstance } from '@/auth/auth.js'
import { onMounted } from 'vue';

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const organization = urlParams.get('organization')

    const auth = getInstance()
    const authorizationParams = {
      'org_id': organization,
      'redirect_uri': window.location.origin
    };

  auth.loginWithRedirect({ authorizationParams });
})
</script>
