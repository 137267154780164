
import { mapGetters } from 'vuex'
import { getInstance } from '@/auth/auth.js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',

  data() {
    return {
      snackbar: {
        messageKey: 'changePasswordRequestSent',
        messages: {
          changePasswordRequestSent:
            'An email with a link to change your password should arrive in your inbox shortly.',
        },
      },
      isSnackbarVisible: false,
      isChangePasswordDisabled: false,
      isChangePasswordLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      currentUserContext: 'permissions/getContext',
      isGlobalAdmin: 'permissions/isGlobalAdmin',
    }),
    name(): string {
      const name = this.currentUserContext.name;
      if (name != null) return name;

      if (this.isGlobalAdmin)
      {
        return "Global Admin"
      }

      return "";
    },
    email(): string {
      return this.currentUserContext.email
    },
    currentUserRole(): string {
      return this.currentUserContext.displayedRole
    },
    displayedScope(): string {
      const scopes = new Set(
        this.currentUserContext.roleAssignments.map((ra) => ra.BusinessUnitName)
      )

      return [...scopes].join(' | ')
    },
  },
  methods: {
    async changePasswordClicked() {
      this.isChangePasswordLoading = true

      const authService = getInstance()
      const promise = authService.requestPasswordReset()

      this.snackbar.messageKey = 'changePasswordRequestSent'
      this.isSnackbarVisible = true
      this.isChangePasswordLoading = false
      this.isChangePasswordDisabled = true

      await promise
    },
  },
})
